.pagination .current {
  background-color: $primary;
  padding: 0;

  a {
    color: $white;

    &:hover {
      background-color: transparent;
    }
  }
}

ul.pagination {
  margin-top: 20px;
  padding: 0;
  text-align: center;
}
