$primary: #5370C4;
$secondary: #646464;
$white: #ffffff;
$light-text: #d7d7d7;
$alt-second: #343841;
$light-background: #F4F4F4;
$text: #646464;
$light_text: #DFDDDD;
$dark_text: #555541;
// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@font-face {
	font-family: "Avenir LT W01_35 Light1475496";
	src: url("../fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
	src: url("../fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),url("../fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("../fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),url("../fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype"),url("../fonts/b0268c31-e450-4159-bfea-e0d20e2b5c0c.svg#b0268c31-e450-4159-bfea-e0d20e2b5c0c") format("svg");
}
@font-face {
	font-family: "Avenir LT W01_45 Book1475508";
	src: url("../fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
	src: url("../fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"),url("../fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),url("../fonts/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),url("../fonts/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype"),url("../fonts/0979215b-3a1b-4356-9c76-e90fa4551f1d.svg#0979215b-3a1b-4356-9c76-e90fa4551f1d") format("svg");
}
@font-face {
	font-family: "Avenir LT W01_65 Medium1475532";
	src: url("../fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix");
	src: url("../fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix") format("eot"),url("../fonts/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),url("../fonts/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff"),url("../fonts/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf") format("truetype"),url("../fonts/3604edbd-784e-4ca7-b978-18836469c62d.svg#3604edbd-784e-4ca7-b978-18836469c62d") format("svg");
}
@font-face {
	font-family: "Avenir LT W01_85 Heavy1475544";
	src: url("../fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
	src: url("../fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"),url("../fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("../fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"),url("../fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype"),url("../fonts/20577853-40a7-4ada-a3fb-dd6e9392f401.svg#20577853-40a7-4ada-a3fb-dd6e9392f401") format("svg");
}
@font-face {
	font-family: "Avenir LT W01_95 Black1475556";
	src: url("../fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix");
	src: url("../fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix") format("eot"),url("../fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),url("../fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff"),url("../fonts/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf") format("truetype"),url("../fonts/93603a74-2be0-436c-83e1-68f9ef616eaf.svg#93603a74-2be0-436c-83e1-68f9ef616eaf") format("svg");
}
@mixin Avenir-Light {
	font-family: 'Avenir LT W01_35 Light1475496', sans-serif;
	font-weight: normal;
}
@mixin Avenir-Book {
	font-family: 'Avenir LT W01_45 Book1475508', sans-serif;
	font-weight: normal;
}
@mixin Avenir-Medium {
	font-family: 'Avenir LT W01_65 Medium1475532', sans-serif;
	font-weight: normal;
}
@mixin Avenir-Heavy {
	font-family: 'Avenir LT W01_85 Heavy1475544', sans-serif;
	font-weight: normal;
}
@mixin Avenir-Black {
	font-family: 'Avenir LT W01_95 Black1475556', sans-serif;
	font-weight: normal;
}
$font: 'Avenir-Book', sans-serif;
$font-medium: 'Avenir-Medium', sans-serif;
$font-heavy: 'Avenir-Heavy', sans-serif;
$font-oblique: 'Avenir-Oblique', sans-serif;
$font-light: 'Avenir-Light', sans-serif;
$font-book: 'Avenir-book', sans-serif;

a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
span,
ul {
	// font-family: $font;
	// font-weight: 500;
	@include Avenir-Book;
	margin-top: 0;
	padding: 0;
	color: $text;
}

h1 {
	font-size: 2em;
}

h2 {
	font-size: 1.5em;
}

h3 {
	font-size: 1.17em;
}

h4 {
	font-size: 1.12em;
}

h5 {
	font-size: 0.83em;
}

h6 {
	font-size: 0.75em;
}

p {
	font-size: 18px;
}

a {
	text-decoration: none;
}

.btn-aw {
	@extend .button;
	background: {
		color: $primary;
	}
	color: $white;
	font-weight: 400;
	text-align: center;
	font-size: 1.2rem;
	margin: 0;
	padding: 10px 60px;
	transition: all 0.3s ease-in-out;
	border-radius: 2px;
	/* Rectangle 4: */
	/* Button: */
	font-family: $font-medium;
	font-size: 16px;
	color: #FFFFFF;
	letter-spacing: -0.04px;
	border-radius: 30px;
	text-decoration: none;

	&:hover {
		opacity: 0.8;
		background: {
			color: $primary;
		}
	}

	&.inverse {
		background: {
			color: $secondary;
		}

		&:hover {
			background: {
				color: $alt-second;
			}
		}
	}

	&.full {
		width: 100%;
	}

	&.arrow {
		&:after {
			top: 1px;
			position: relative;
			content: '\f178';
			font-family: FontAwesome;
			font-weight: normal;
			font-style: normal;
			margin: 0 0 0 10px;
			text-decoration: none;
		}
	}
}
// a tag standard classes
a {
	color: inherit;

	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

.full-row {
	@extend .row;
	max-width: 100vw;
	overflow: hidden;
}

.aw-spacer {
	height: 1px;
}

.section {
	padding: 4rem 0;
}
// Vertical Align [ to use @include vertical-align() ]
@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	transform: translateY(-50%);
}
// Margin on Recaptcha
.wpcf7-form-control-wrap {
	margin: 0 0 1rem;
}
//
//
//

.icon-button {
	padding: 10px 44px 10px 22px;
	position: relative;

	&:after {
		position: absolute;
		height: 14px;
		width: 14px;
		font-family: FontAwesome;
		line-height: 14px;
		font-size: 14px;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
	}
	&.long-arrow-right {

		&:after {
			content: "\f178";
		}
	}
	&.long-arrow-down {

		&:after {
			top: 48%;
			content: "\f175";
		}
	}
	&.whatsapp {
		background: {
			color: #33D366;
		}
		&:after {
			content: "\f232";
		}
	}
	&.share-square-o {
		background: {
			color: #FA2E64;
		}
		&:after {
			content: "\f045";
		}
	}
	&.bars {
		background: {
			// color: #DC2934;
		}
		&:after {
			content: "\f0c9";
		}
	}
}

.button-dit {
	padding: 10px 40px;
	background-image: linear-gradient(90deg, #BAD794 0%, #85CDA8 49%, #67BAA4 100%);
	&:hover{
		opacity: 0.8;
	}
}

.button-uws {
	padding: 10px 40px;
	background-image: linear-gradient(90deg, #67B583 0%, #68BBDB 48%, #447CAC 100%);
	&:hover{
		opacity: 0.8;
	}
}

.button-qub {
	padding: 10px 40px;
	background-image: linear-gradient(90deg, #4877AB 0%, #A6307A 51%, #DC2934 100%);
	&:hover{
		opacity: 0.8;
	}
}
