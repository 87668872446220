/* Navigation breakpoints */

.desktop-menu, .top-bar-left {
  @include show-for(medium);
}

#mobile-menu, .title-bar {
  @include hide-for(medium);
}

/* Mobile menu */

.off-canvas {
 > ul.menu {
    height: 100vh;
    padding: 1rem;
  }
}

.title-bar-title {
  a {
    font-size: 1rem;
    color: #B9B9B9;
  }
}

/* Tablet and desktop menu */
.top-bar {

  .menu .home a {
    font-size: 1rem;
    color: #B9B9B9;
  }

  .menu a {
    height: 45px;
    line-height: 45px;
    color: #e6e6e6;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1;

    &:hover:not(.button) {
      background-color: #2a2a2a;
    }
  }

  .menu>li:not(.menu-text)>a { padding: 1rem; }
  .menu li:not(:last-child) { border-right: 1px solid #4e4e4e; }

  .dropdown.menu .submenu {
    background-color: #121212;
    color: #e6e6e6;
    border: 0;
  }

  .dropdown.menu .has-submenu.is-down-arrow a { padding-right: 1rem; }
  .dropdown.menu .has-submenu.is-down-arrow > a::after { border: 0; }

  .dropdown.menu:first-child >
    li.is-dropdown-submenu-parent >
    a::after {
      display: none;
    }

}
/* Basic styles for post navigation. Nicolas Gallagher's "Micro clearfix" https://css-tricks.com/snippets/css/clear-fix/ */
.post-navigation:before,
.post-navigation:after {
	content: "";
    	display: table;
}
.post-navigation:after {
	clear:both;
}
/* Text meant only for screen readers. Addresses part of theme-check issue #840. https://github.com/Automattic/_s/blob/master/style.css */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}
.nav-previous {
	float:left;
}
.nav-next {
	float:right;
}


// Menu 2 Styling

.main-navigation__menu {
  @include breakpoint(medium) {
    margin: 2rem 0;
  }
}

.menu {
  font-weight: $global-weight-bold;
  font-size: 1.125rem;

  @include breakpoint(medium) {
    text-align: right;
  }
}

.menu .active > a {
  background: {
      color: $secondary;
    }
}

.menu li {
  display: block;

  @include breakpoint(medium) {
    display: inline-block;
  }
}

.menu > li > a {
  color: $black;
  padding: 0.7rem;
}

/* Dropdown */
.menu > li > ul {
  text-align: left;
}

.menu > li > ul > li > a {
  color: $white;
  line-height: 1.4;
  padding: 0.7rem;
  background-color: $secondary;
  &:hover {
    background-color: $alt-second;
    color: $white;
  }
}

.menu li > ul > li {
  display: block;
  font-size: 1rem;
  &.active {
    background: {
      color: $secondary;
    }
  }
}

.submenu {
  width: 100%;

  @include breakpoint(large) {
    width: auto;
  }
}

.is-dropdown-submenu {
  border: 0;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  // border-color: $secondary transparent transparent;
  
}

.dropdown.menu > li.is-dropdown-submenu-parent.active > a::after {
  // border-color: $white transparent transparent;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a {
	padding-left: 0.7rem;
}
.aw-menu-icon {
	height: 50px;
	p {
		font-size: 20px;
		display: inline;
		padding: 0 10px 0 0;
	}
}
.aw-menu-icon::after {
  	font: normal normal normal 20px FontAwesome;
  	content: "\f0c9";
  }
