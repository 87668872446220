.site-header {
  background: {
    color: $white;
  }

  .header-top {
    // height: 10px;
    padding: 9.5px 0;
    background: {
      color: $primary;
    }

    .search-container {
      display: none;
      @include breakpoint(medium) {
        display: block;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      text-align: center;
      @include breakpoint(medium) {
        text-align: right;
      }

      li {
        display: block;
        padding: 0;
        color: $white;
        @include breakpoint(medium) {
          display: inline-block;
          padding: 0 0 0 1.875rem;
        }
        @include breakpoint(large) {
          display: inline;
        }
      }
    }
  }

  .header-bottom {
    padding: 1rem 0;
    justify-content: center;
    @include breakpoint(medium) {
      justify-content: space-between;
      padding: 5px 0;
    }

    img {
      height: 80px;
      display: block;
      margin: 0 auto 1rem;
      @include breakpoint(medium) {
        margin: 0;
      }
    }

    p {
      color: $text;
      text-align: center;
      margin: 0.3rem 0;
      font-size: 14px;
      @include breakpoint(medium up) {
        text-align: right;
      }

      a {
        text-decoration: none;
      }
    }

    .menu-trigger {
      text-align: center;
      @include breakpoint(medium) {
        text-align: right;
      }
      @include breakpoint(large) {
        display: none;
      }
    }

    .main-navigation {
      ul {
        margin: 0;
        text-align: center;
        @include breakpoint(large) {
          text-align: right;
        }

        li {
          display: block;
          @include breakpoint(large) {
            display: inline-block;
          }

          &.active {
            a {
              background: {
                color: transparent;
              }
            }
          }

          a {
            @include Avenir-Light;
            color: $text;
            font-size: 18px;

            &:hover {
              color: $secondary;
            }
          }

          &.is-dropdown-submenu-parent {
            a {
              padding-left: 1.5rem;
              @include breakpoint(large) {
                padding-left: 0.7rem;
              }

              &:after {
                position: absolute;
                top: 50%;
                // transform: translateY(-50%);
                border: 0;
                font-family: FontAwesome;
                font-size: 14px;
                content: "\f107";
                line-height: 6px;
                height: 6px;
                width: 10px;
                // background: {
                // color: red;
                // }
              }
            }

            ul {
              @include breakpoint(large) {
                margin-top: 25px;
                border-top: 2px solid $primary;
                text-align: left;
              }
              // padding: 0.18rem 0;
              background: {
                // color: $light-background;
              }

              &.js-dropdown-active {
                position: relative;
                @include breakpoint(large) {
                  position: absolute;
                  z-index: 100;
                }
              }

              li {
                padding: 0;
                margin: 0;

                a {
                  padding: 0.18rem 0.7rem 0.18rem 1.4rem;
                  display: block;
                  background: {
                    color: $white;
                  }
                  position: relative;

                  &:before {
                    content: '\f105';
                    position: absolute;
                    top: 12px;
                    border: 0;
                    font-family: FontAwesome;
                    font-size: 14px;
                    line-height: 6px;
                    height: 6px;
                    width: 10px;
                    left: 10px;
                  }

                  &:after {
                    display: none;
                  }

                  &:hover {
                    background: {
                      color: $primary;
                    }
                    color: $white;
                  }
                }
              }
            }
          }

          &.members-button {
            @include breakpoint(large) {
              margin-left: 20px;
            }

            a {
              @extend .btn-aw;
              padding: 10px 40px 10px 20px;
              position: relative;
              @include Avenir-Medium;

              &:after {
                position: absolute;
                height: 14px;
                width: 14px;
                font-family: FontAwesome;
                line-height: 14px;
                font-size: 14px;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                content: "\f178";
                background: {
                  // color: $text;
                }
              }
            }
          }
        }
      }
    }
  }
}
/*
* Home Slider
*/
.home--hero {
  position: relative;
  overflow: hidden;
  background: {
    color: $secondary;
  }
  // height: 573px;
  &:after {
    content: '';
    height: 500px;
    background: {
      image: url("../images/general/overlay-v1.png");
      // position: relative;
      size: cover;
      position: center center;
    }
    position: absolute;
    top: auto;
    right: 0;
    bottom: -250px;
    left: 0;
    z-index: 1;
    opacity: 0.6;
    @include breakpoint(medium up) {
      bottom: -210px;
    }
  }

  .home--hero-slide {
    padding: 8rem 0;
    color: $white;
    background: {
      // image: url("../images/hero/hero-home-1.jpg");
      position: center;
      repeat: no-repeat;
      size: cover;
    }

    h1 {
      color: inherit;
      display: block;
      font-size: 52px;
      @include Avenir-Heavy;
      margin: 0;
      line-height: 71px;
    }

    h2 {
      color: inherit;
      display: block;
      font-size: 52px;
      @include Avenir-Light;
      margin: 0 auto 0.5rem;
      line-height: 71px;
    }

    p {
      color: inherit;
      padding-right: 6rem;
      font-size: 18px;
      @include Avenir-Medium;
      margin: 0;
    }
    .home--hero-text{
      position: relative;
      z-index: 999;
    }
  }
}

.home-opening {
  background: {
    color: $white;
  }
  position: relative;
  z-index: 10;

  img {
    // width: 210px;
    // padding: 1rem;
    display: block;
    margin: 0 auto;
    @include breakpoint(medium only) {
      padding: 0 6rem;
    }
  }

  p {
    font-family: $font-medium;
    font-size: 18px;
    color: $text;
    letter-spacing: 0.78px;
    line-height: 43px;
  }
}

.home-project-partners {
  // padding: 0 0 4rem 0;
  background: {
    color: $light-background;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  h2 {
    font-size: 38px;
    margin: 0 0 3rem;
  }

  h2,
  h3 {
    text-align: center;
  }
}

.footer-sponsors {
  img {
    display: block;
    margin: 0 auto;
    max-width: 322px;
    width: 100%;
    height: auto;
  }
}

.footer-main {
  padding: 4rem 0 6rem;
  position: relative;
  background: {
    color: $primary;
  }
  @include breakpoint(medium up) {
    padding: 4rem 0 18rem;
  }

  ul {
    color: $white;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-family: $font-book;
      color: inherit;
      font-size: 18px;

      a {
        text-decoration: none;
      }
    }

    &.sub-menu {
      margin-top: 27px;
    }
  }

  .overlay {
    // padding: 4rem;
    height: 150px;
    background: {
      image: url("../images/general/overlay-v3.png");
      // position: relative;
      size: 100%;
      position: center bottom;
      repeat: no-repeat;
    }
    overflow: hidden;
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    @include breakpoint(medium up) {
      height: 300px;
    }
  }

  footer.footer-meta {
    position: absolute;
    bottom: 0;
    z-index: 100;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 75rem;

    p {
      margin: 0;
      // padding: 1rem 0;
      color: $white;
    }

    .column {
      text-align: center;

      p {
        padding: 1rem 0 0;
      }

      &:nth-child(even) {
        p {
          padding: 0 0 1rem;
        }
      }
      @include breakpoint(large) {
        text-align: left;

        p {
          padding: 1rem 0;
        }

        &:nth-child(even) {
          text-align: right;

          p {
            padding: 1rem 0;
          }
        }
      }
    }
  }
}
//
//
//

#newsletterform {
  margin-top: 1rem;
}

#newsletterform,
#searchform {
  margin: 0;

  .input-group {
    margin: 0;
    position: relative;
    transition: opacity 1s ease-in-out;
    display: block;

    .button {
      background: {
        color: $light_text;
      }
      border-radius: 50%;
      height: 30px;
      width: 30px;
      padding: 4.5px 4.75px;
      color: $primary;
      font-size: 10px;
      border: 0;
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        color: $white;
        background: {
          color: $secondary;
        }
      }
    }

    input.input-group-field {
      border-radius: 15px;
      height: 30px;
      border: 0;
      padding: 7px 40px 7px 15px;
      font-size: 14px;
      color: $text;
      width: 100%;

      &::placeholder {
        padding-top: 1px;
      }
    }
  }
}
//
//
//

.page-hero {
  padding: 31px 0;
  position: relative;
  color: $white;
  background: {
    image: url("../images/page--hero.jpg");
    position: center;
    repeat: no-repeat;
    size: cover;
  }

  h1 {
    @include Avenir-Medium;
    font-size: 42px;
    margin: 0;
    color: inherit;
  }

  h2 {
    @include Avenir-Heavy;
    font-size: 24px;
    margin: 0;
    color: inherit;
  }

  * {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    content: '';
    background: {
      color: $text;
    }
    opacity: 0.6;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
}
//
//
//

.aw-custom-login-form {
  .login-page-form {
    padding: 4rem;
    background: {
      color: $light-background;
    }

    img {
      padding: 0 2rem 1rem;
    }

    input[type='text'],
    input[type='password'] {
      padding: 19.5px 1.5rem;
      height: 53px;
      border-radius: 26.5px;

      &::placeholder {
        @include Avenir-Medium;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .login-span {
      position: relative;
      display: inline-block;

      .btn-aw {
        @extend .btn-aw;
        padding: 10px 44px 10px 30px;
        position: relative;
        @include Avenir-Medium;
      }

      &:after {
        color: $white;
        position: absolute;
        height: 10px;
        width: 14px;
        font-family: FontAwesome;
        line-height: 10px;
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        content: "\f178";
        @include breakpoint (small only) {
          top: 35%;
        }
      }
    }

    .login-button-forgot {
      display: flex;
      align-items: center;
    }

    .login-submit {
      max-width: 50%;
      flex: 0 0 50%;
      margin: 0;
    }

    .login-remember {
      text-align: right;
      max-width: 50%;
      flex: 0 0 50%;
      margin: 0;

      label {
        @include Avenir-Medium;
        font-size: 14px;

        a {
          @include Avenir-Medium;
          font-size: 14px;
        }
      }

      input#rememberme {
        top: -1px;
        margin-right: 5px;
        position: relative;
      }
    }

    #password-strength {
      position: relative;
      height: 10px;
      // overflow: hidden;
      font-size: 0;
      line-height: 0;
      padding: 0;
      border-width: 5px;
      border-radius: 5px;
      display: block;
      margin-top: 35px;

      &:before {
        content: 'Password Strength:';
        display: block;
        position: absolute;
        top: -22px;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 14px;
        @include Avenir-Medium;
        line-height: 14px;
        color: $text;
        z-index: 100;
        text-align: left;
        background: {
          // color: red;
        }
      }
    }
  }
}
//
//
//

.aw-membership-account {
  h3 {
    @include Avenir-Book;
    font-size: 36px;
    margin: 0;
  }

  .welcome-meta {
    &:nth-child(even) {
      ul {
        text-align: right;
        list-style: none;
        margin: 0;

        li {
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .project-institutions-column {
    h4 {
      @include Avenir-Heavy;
      text-align: center;
      font-size: 24px;
      color: $text;
    }
  }
}

.project-institutions {
  text-align: center;

  .column {
    position: relative;
    padding: 0.625rem 0.625rem 4rem;
    @include breakpoint(medium) {
      padding: 0.9375rem 0.9375rem 4rem;
    }
    @include breakpoint(large) {
      padding: 1.875rem 0.9375rem;
    }

    h5 {
      color: $text;
      @include Avenir-Medium;
      font-size: 20px;
      margin: 0 0 1rem;
    }

    p {
      color: $text;
      @include Avenir-Light;
      text-align: center;
      padding: 0 2rem;
    }

    &:after {
      position: absolute;
      content: '';
      height: 3px;
      @include breakpoint(large) {
        width: 2px;
        height: auto;
        top: 0;
        bottom: 0;
        left: auto;
      }
      background: {
        color: $light-background;
      }
      right: 0;
      bottom: 0;
      left: 0;
      // opacity: 0.2;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.membership-portal-applications {
  background: {
    color: $light-background;
  }

  h4 {
    margin-bottom: 4rem;
  }

  .application-icon {
    text-align: center;

    i.fa {
      color: $primary;
      font-size: 80px;
      margin-bottom: 1rem;
    }

    h5 {
      @include Avenir-Medium;
      font-size: 18px;
    }
  }
}

.home--welcome {
  h2 {
    font-size: 32px;
    line-height: 37px;
    // line-height: 98px;
    margin: 0 0 1rem;
    text-align: center;
    @include breakpoint(medium) {
      font-size: 52px;
      line-height: 57px;
      text-align: right;
      margin: 0;
    }
    @include breakpoint(large) {
      font-size: 72px;
      line-height: 77px;
      text-align: left;
    }
    @include Avenir-Light;

    strong {
      display: block;
      @include Avenir-Heavy;
    }
  }

  .breaker {
    display: none;
    @include breakpoint(large) {
      display: block;
    }
  }

  .project-vision {
    p {
      text-align: center;
      font-size: 18px;
      // line-height: 43px;
      @include breakpoint(medium) {
        text-align: left;
        // font-size: 18px;
        line-height: 43px;
      }
      @include Avenir-Medium;
      letter-spacing: 0.78px;
      margin: 0;
    }
  }

  .project-institutions {
    margin-top: 4rem;
  }

  &.contact {
    .project-institutions {
      margin-top: 0;
    }
  }
}
//
//
//

.crossborder-inter-regional {
  .section {
    padding: 0 0.625rem 4rem;
    @include breakpoint(medium) {
      padding: 4rem 0.9375rem;
    }
  }

  img {
    padding-top: 4rem;
    padding-bottom: 2rem;
    width: 100%;
    @include breakpoint(medium) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  text-align: center;
  @include breakpoint(medium) {
    text-align: right;
  }
  padding: 1rem 0;
  background: {
    color: $light-background;
  }
  // h3 {
  //   @include Avenir-Heavy;
  //   font-size: 72px;
  //   color: $dark_text;
  //   margin: 0;
  // }
  h3 {
    font-size: 32px;
    line-height: 37px;
    // line-height: 98px;
    margin: 0 0 1rem;
    // text-align: center;
    @include breakpoint(medium) {
      font-size: 42px;
      line-height: 47px;
      // text-align: right;
      margin: 0;
    }
    @include breakpoint(large) {
      font-size: 72px;
      line-height: 77px;
      // text-align: right;
    }
    @include Avenir-Heavy;
  }

  ul {
    margin: 0 0 2rem;
    list-style: none;
    padding: 0;

    li {
      margin: 0;
      @include Avenir-Light;
      font-size: 28px;
      letter-spacing: 0.58px;
      @include breakpoint(medium) {
        font-size: 38px;
      }
      @include breakpoint(large) {
        font-size: 48px;
      }
    }
  }
}
//
//
//

.home--latest-news {
  &.single-news {
    background: {
      color: $light-background;
    }

    h4 {
      border-bottom: 0;
    }

    .home--kids-corner {
      &:after {
        display: none;
      }
    }

    .sidebar-column {
      position: relative;

      &:after {
        height: 100%;
        width: 3px;
        top: 0;
        right: auto;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: {
          color: $light-background;
        }
        content: '';
        position: absolute;
      }
    }
  }

  h4 {
    @include Avenir-Light;
    font-size: 48px;
    letter-spacing: 0.58px;
    margin: 0 0 2rem;
    border-bottom: 3px solid $light-background;
  }

  .news-article-card {
    padding-bottom: 2rem;
    margin-bottom: calc(2rem - 3px);
    position: relative;

    &:last-child {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      right: 0.9375rem;
      bottom: 0;
      left: 0.9375rem;
      height: 3px;
      background: {
        color: $light-background;
      }
    }

    .card-hero {
      img {
        width: 100%;
      }
      position: relative;

      span.date {
        position: absolute;
        top: 0;
        left: 0.625rem;
        @include breakpoint(medium) {
          left: 0.9375rem;
        }
        padding: 5px 10px;
        color: $white;
        @include Avenir-Heavy;
        font-size: 13px;
        letter-spacing: 0.58px;
        background: {
          color: $primary;
        }
      }
    }

    .card-content {
      color: $dark_text;

      h5 {
        @include Avenir-Heavy;
        font-size: 24px;
        margin: 0;
        // color: $dark_text;
      }

      p {
        @include Avenir-Light;
        font-size: 16px;
        letter-spacing: 0.58px;
        margin: 0;

        span {
          font-size: 18px;
        }

        &:nth-last-child(2) {
          margin: 0 0 1rem;
        }
      }

      &.news {
        a {
          color: $primary;
          @include Avenir-Heavy;
          font-size: 14px;
          letter-spacing: 0.58px;
        }
      }

      &.resource {
        h5 {
          margin-bottom: 1rem;
        }
        position: relative;

        a {
          @include breakpoint(large) {
            position: absolute;
            left: 0.9375rem;
            bottom: 0;
          }
        }
      }
    }
  }
}

.home--kids-corner {
  margin-top: 2rem;
  @include breakpoint(large) {
    margin-top: 0;
  }

  img {
    width: 100%;
  }

  h5 {
    @include Avenir-Light;
    font-size: 24px;
    color: $dark_text;
    margin: 1rem 0;
  }

  p {
    @include Avenir-Light;
    font-size: 16px;
    color: $dark_text;
    margin: 0 0 1.5rem;
  }

  ul {
    list-style: none;
    margin: 0 0 1rem;

    &:last-child {
      margin: 0;
    }

    li {
      font-size: 16px;
      color: $dark_text;
    }
  }
  padding-bottom: 2rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
    @include breakpoint(medium) {
      right: 0.9375rem;
      left: 0.9375rem;
    }
    height: 3px;
    background: {
      color: $light-background;
    }
  }
}
//
//
//

.home-tweets-ezine {
  // padding: 4rem 0 0;
  h3 {
    @include Avenir-Light;
    font-size: 48px;

    span {
      display: block;
    }
  }
}
//
//
//

.twitter-feed-scroll {
  box-shadow: 0 2px 25px 0 rgba(0,0,0,0.50);
  max-height: 650px;
  overflow: scroll;
}
//
//
//

.project-institutions {
  &.tabs {
    border: 0;

    &:before {
      display: none;
    }

    a {
      @extend .btn-aw;

      &.button-dit {
        @extend .button-dit;
      }

      &.button-uws {
        @extend .button-uws;
      }

      &.button-qub {
        @extend .button-qub;
      }

      &:hover {
        background: {
          color: $primary;
        }
      }
    }

    .tabs-title {
      position: relative;
      padding-bottom: 2rem;

      &:after {
        position: absolute;
        display: block;
        content: '\f107';
        color: $text;
        font-family: FontAwesome;
        font-size: 36px;
        height: 12px;
        width: 20px;
        background: {
          color: transparent;
        }
        top: auto;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: all 0.5s ease-in-out;
      }

      &.is-active {
        &:after {
          opacity: 1;
        }

        a {
          &.button-dit {
            @extend .button-dit;
          }

          &.button-uws {
            @extend .button-uws;
          }

          &.button-qub {
            @extend .button-qub;
          }
        }
      }
    }
  }
}

.about--tab-content {
  background: {
    color: $light-background;
  }

  .tabs-content {
    background: {
      color: transparent;
    }
    border: 0;

    .individual-tab {
      padding: 0 0.625rem;
      @include breakpoint(medium) {
        padding: 0 0.9375rem;
      }

      &.is-active {}

      .location--map {
        height: 300px;
        @include breakpoint(large) {
          height: auto;
        }
      }

      h3 {
        @include Avenir-Heavy;
        font-size: 36px;
        letter-spacing: 0.78;
        margin: 0;
      }

      h4 {
        @include Avenir-Medium;
        font-size: 24px;
        letter-spacing: 0.78;
        margin: 0 0 0.5rem;
      }

      p {
        @include Avenir-Medium;
        font-size: 18px;
        letter-spacing: 0.78;
        line-height: 30px;

        &:last-child {
          margin: 0;
        }
      }

      .contact-column {
        padding: 4rem 0.625rem;
        @include breakpoint(medium) {
          padding: 4rem 0.9375rem;
        }

        &.title {
          padding-bottom: 0;
        }

        &.content {
          padding-top: 0;
        }

        h5 {
          @include Avenir-Medium;
          font-size: 24px;
          margin: 0 auto 1rem;
        }

        ul {
          list-style: none;
          margin-bottom: 2.5rem;

          &.address {
            margin-bottom: 2.5rem!important;
            @include breakpoint(medium) {
              margin-bottom: 0!important;
            }
            @include breakpoint(large) {
              margin-bottom: 2.5rem!important;
            }
          }

          li {
            @include Avenir-Book;
            font-size: 18px;

            i.fa {
              padding-right: 10px;
              width: 30px;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
//
//
//

.outreach-banner {
  padding: 0 0 2rem;
  position: relative;
  background: {
    color: $light-background;
  }
  @include breakpoint(large) {
    padding: 2rem 0;
    background: {
      color: transparent;
    }
  }

  h3 {
    @include Avenir-Book;
    font-size: 32px;
    letter-spacing: 0.52px;
    margin: 0;
  }

  p {
    @include Avenir-Book;
    font-size: 18px;
    letter-spacing: 0.52px;
    margin: 0 0 0.5rem;
    @include breakpoint(large) {
      padding: 0 6rem 0 0;
    }
  }

  a {}

  .outreach-banner-image {
    // top: -2rem;
    // position: rel
    // z-index: 100;
    margin-bottom: 2rem;
    background: {
      image: url("../images/outreach-banner.jpg");
      position: center;
      repeat: no-repeat;
      size: cover;
    }
    min-height: 200px;
    @include breakpoint(large) {
      margin: 0;
      min-height: none;
    }
  }

  .full-row {
    background: {
      color: $light-background;
    }
    @include breakpoint(large) {
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
//
//
//

.project-gallery {
  @include breakpoint (small only) {
    text-align: center;
  }
  .column {
    padding: 0.625rem;
    @include breakpoint(medium only) {
      padding: 0.9375rem;
      // padding: 0;

    }

    .image {
      background: {
        color: $primary;
      }
      width: 100%;
      // height: 20px;
    }
  }
}
//
//
//

.project--welcome {
  padding: 4rem 0 0;

  &.outreach {
    padding: 0;
  }

  p {
    // font-size: 13px;
    @include Avenir-Light;
    // letter-spacing: 0.58px;
  }

  .project-content-row {
    h1 {
      @include Avenir-Medium;
      font-size: 36px;
      margin: 0 0 1rem;
    }

    h2 {
      @include Avenir-Heavy;
      font-size: 24px;
      margin: 0;
    }

    img {
      width: 100%;
    }

    .tabs-content {
      padding: 0;
      border: 0;
    }

    .content-column {
      margin-top: 1rem;
      transition: all 0.5s ease-in-out;

      p {
        &:last-child {
          margin: 0;
        }
      }

      ul {
        &:last-child {
          margin: 0;
        }
        list-style: none;
        @extend p;
        @include Avenir-Light;
      }

      &.resource {
        margin: 0;
        padding: 0 0.9375rem;

        .main-border {
          border: 3px solid $light-background;

          .internal-content {
            padding: 2rem;

            h4 {
              @include Avenir-Light;
              font-size: 24px;
              margin: 0 0 1rem;
            }
          }
        }
      }
    }
  }

  .tab-bar {
    border: 3px solid $light-background;
    padding: 2rem;
    position: relative;

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: {
        // color: $primary;
        image: url("../images/overlay.png");
        size: cover;
        position: center top;
      }
      content: '';
      height: 130px;
    }

    h4 {
      @include Avenir-Light;
      font-size: 24px;
      color: $dark_text;
    }

    ul {
      @extend .tabs;
      list-style: none;
      margin: 0 auto 130px;
      border: 0;

      &:before {
        display: none;
      }

      li {
        display: block;
        width: 100%;
        transition: all 0.5s ease-in-out;

        &.is-active {
          padding-left: 10px;

          a {
            background: {
              color: transparent;
            }
          }
        }

        a {
          padding: 0;
          color: $text;
          font-size: 1rem;
        }
        @include Avenir-Light;
        // font-size: 14px;
        margin-bottom: 0.5rem;

        i.fa {
          font-size: 14px;
        }
      }
    }
  }
}
//
//
//

.collaborators-opening {
  * {
    &:last-child {
      margin: 0;
    }
  }

  img {
    width: 100%;
  }
}
//
//
//

.our-collaborators {
  padding: 0 0 4rem;

  h3 {
    @include Avenir-Book;
    font-size: 52px;
    margin: 0 0 1rem;
    line-height: 65px;
  }

  .collab-row {
    margin-bottom: 4rem;
    @include breakpoint(large) {
      &:nth-child(even) {
        .collab-column-img {
          order: 2;
        }

        .collab-column {
          order: 1;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
//
//
//

.faqs-accordion {
  padding: 0 0 4rem;

  &.outreach {
    padding: 0;
  }

  &.links {
    padding: 4rem 0;

    .links-wrapper {
      background: {
        color: $light-background;
      }
      padding: 1rem 1rem 0.5rem;
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h3 {
      @include Avenir-Light;
      font-size: 36px;
      letter-spacing: 0.58px;
      margin: 0 0 1rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 200px;
        height: 3px;
        background: {
          color: $light-background;
        }
      }
    }
  }

  ul.accordion {
    list-style: none;

    li {
      padding: 0.5rem 0;

      .accordion-content {
        padding: 1.5rem 1rem;

        * {
          @include Avenir-Light;

          &:last-child {
            margin: 0;
          }
        }
      }

      a {
        width: 100%;
        display: block;
        color: $primary;
        font-size: 20px;
        @include Avenir-Medium;
        position: relative;

        &:after {
          position: absolute;
          width: 16px;
          height: 18px;
          content: "\f0fe";
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
          line-height: 18px;
          font-family: FontAwesome;
        }
      }

      &.is-active {
        a {
          &:after {
            content: "\f146";
          }
        }
      }

      &.link {
        a {
          &:after {
            content: '\f0c1';
          }
        }
      }
    }
  }
}
//
//
//

.outreach-selector {
  padding: 0 0 4rem;

  .column {
    margin-bottom: 1.875rem;

    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  .container {
    img {
      width: 100%;
    }
    // padding: 1rem;
    border: 3px solid $light-background;

    .content {
      padding: 2rem;

      h3 {
        @include Avenir-Light;
        font-size: 24px;
        letter-spacing: 1.04px;
      }

      p {
        @include Avenir-Book;
        font-size: 14px;
        letter-spacing: 0.87px;
        margin: 0 0 3rem;
      }
    }
  }
}
//
//
//

.news--article {
  .card-hero {
    img {
      width: 100%;
      @include breakpoint (large) {
        width: 50%
      }
    }
    position: relative;

    span.date {
      position: absolute;
      top: 0;
      left: 0.625rem;
      @include breakpoint(large) {
        left: 0.9375rem;
      }
      padding: 5px 10px;
      color: $white;
      @include Avenir-Heavy;
      font-size: 13px;
      letter-spacing: 0.58px;
      background: {
        color: $primary;
      }
    }
  }

  .article-title {
    margin: 2rem auto 1rem;
    position: relative;

    h3 {
      @include Avenir-Heavy;
      font-size: 24px;
      letter-spacing: 0.58px;
      margin: 0 0 0.5rem;
    }

    h4 {
      @include Avenir-Light;
      font-size: 18px;
      letter-spacing: 0.58px;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0.9375rem;
      bottom: 0;
      left: 0.9375rem;
      height: 3px;
      background: {
        color: $light-background;
      }
    }
  }

  .article-content {
    p {
      &:last-child {
        margin: 0;
      }
    }

    a.back-to-news {
      color: $primary;
      @include Avenir-Heavy;
      font-size: 20px;
      letter-spacing: 0.58px;
    }

    .article-gallery {
      margin-top: 3rem;
      margin-bottom: 4rem;

      .service-gallery {
        img {
          width: 100%;
          margin-bottom: 1.25rem;
          @include breakpoint(medium) {
            margin-bottom: 0;
          }
        }
        &:last-child {
          img {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}


/*
* Advisory Table
*/
.advisory-table{
  h3{
    font-size: 24px;
    text-align: left;
    letter-spacing: 0.58px;
    margin: 0 0 36px 0;
  }
  p{
    font-size: 18px;
    text-align: center;
  }
  .advisory-table-row-top{
    background: {
      color: $light-background;
    }
  }
  .advisory-table-row{
    background: {
      color: $light-background;
    }
    &:nth-child(even) {
      background: {
        color: $light-background;
      }
    }
  }

  &.leadership{
    p{
      font-size: 14px;
    }
    .advisory-table-row-top{
      p{
        font-family: $font-heavy;
        font-size: 14px;
      }
    }
  }
}

//
//
//

#subscriber-response {
  color: $white;
  margin-top: 1rem;

  @include Avenir-Heavy;
}

label.error {
  color: $white;
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body::-webkit-scrollbar { display: none;  }
     html, body { -ms-overflow-style: none; overflow: auto; }
}


.service-gallery {
  img {
    @include breakpoint (medium only) {
      max-height: auto;
      min-width: 100%;
    }
    @include breakpoint (large) {
      min-height: 251px;
    }
  }
}
