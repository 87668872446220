.wpcf7-form-control-wrap {
  margin: 0 0 1rem;
  display: block;
  .wpcf7-form-control {
    margin: 0;
  }
}

.wpcf7-not-valid-tip {
  // @include Ubuntu-Medium;
}

.wpcf7-response-output {
  margin: 0 0 1rem!important;
  border-radius: 2px; // @include Ubuntu-Medium;
  // font-size: 14px;
  line-height: 19px;
  border: 0!important;
  padding: 1rem;
  text-align: center;
  padding: 0.75rem 1rem!important;
  font-family: $font;

  &.wpcf7-validation-errors {
    // color: $secondary;
    background: {
      color: $secondary;
    }
    color: $white;
  }

  &.wpcf7-mail-sent-ok {
    background: {
      color: $primary;
    }
    color: $white;
  }

  &.wpcf7-mail-sent-ng {
    background: {
      color: $secondary;
    }
    color: $white;
  }

  &.wpcf7-spam-blocked {
    // border: 0;
    background: {
      color: $secondary;
    }
    color: $white;
  }
}
