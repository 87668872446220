#page-full-width {
  @include grid-row();
  margin-top: 3rem;

  .main-content {
    @include breakpoint(small) {
      @include grid-column(12);
    }
  }
}
