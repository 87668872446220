.fj-footer-container {
	.fj-footer {
		background: {
			color: $secondary;
		}
		padding: 20px 0;
		color: $white;
		font-size: 0.8rem;
		a {
			color: $primary;
		}
		.left {
			text-align: left;
		}
		.right {
			text-align: right;
		}
	}
}

@include breakpoint(medium down) {
	.fj-footer-container {
		.fj-footer {
			.left, .right {
				text-align: center;
			}
		}
	}
}
