// Simple Sharing Buttons

ul.share-buttons{
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 0!important;
  margin-right: 0!important;
  text-align: right;
}

ul.share-buttons li{
  display: inline;
  color: $text;
  flex: none;
  padding: 0 5px;
  font-family: $font;
  i.share {
    color: rgba($text, 0.5);
    &:hover {
      color: $primary;
    }
  }
}

ul.share-buttons .sr-only {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
