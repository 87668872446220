#contactpage {
  padding: 0 0 50px;
  margin: 100px 0 0 0;
  #mail-status {
    margin: 20px 0 10px;
    color: $white;
    font-weight: 600;
    p {
      margin: 0;
      padding: 10px;
      border-radius: 8px;
      &.aw-mail-error {
        background: {
          color: $secondary;
        }
      }
      &.aw-mail-success {
        background: {
          color: $primary;
        }
      }
    }
  }


  .mlt-contact-sidebar, .mud-contact-form {
    h4 {
      text-transform: uppercase;
      font-weight: 600;
    }
    ul {
      margin: 0 0 0 20px;
      li {
        list-style-type: none;
        margin: 0 0 5px 0;
        &:nth-child(1) {
          .fa {
            padding: 0 18px 0 0;
            margin: 0 0 0 -20px;
          }
        }
        &:nth-child(2) {
          .fa {
            padding: 0 18px 0 0;
            margin: 0 0 0 -20px;
          }
        }
        &:nth-child(3) {
          .fa {
            padding: 0 14px 0 0;
            margin: 0 0 0 -20px;
          }
        }
        &:nth-child(4) {
          .fa {
            padding: 0 18px 0 0;
            margin: 5px 0 0 -16px;
            height: 40px;
            float: left;
          }
        }
        &:nth-child(5) {
          .fa {
            padding: 0 14px 0 0;
            margin: 0 0 0 -20px;
          }
        }
        &:nth-child(6) {
          margin: 0 0 50px;
          .fa {
            padding: 0 15px 0 0;
            margin: 0 0 0 -20px;
          }
        }
      }
    }
    .mlt-contact-socialmedia {
      //margin: 100px 0 0 0;
      h4 {
        margin: 0;
      }
      h5 {
        font-size: 1.3rem;
        font-weight: 600;
      }
      .aw-underline {
        margin: 5px 0 20px;
      }
      a {
        color: $secondary;
        margin: 20px 0 0 0;
        &:hover {
          color: $primary;
        }
        
      }
    }
  }

  .mud-contact-form-footer {
    div {
      padding: 0 0.9375rem 10px 0;
      input {
        margin: 0;
      }
    }
  }
}