/*
  Foundation for Sites components:
  Import the building blocks you need for your project.
  Reducing the number of imported components will optimize page speed.
  ---------------------------------------------------------------------
  The Foundation framework is built by Zurb
  Licensed under MIT Open Source
  ---------------------------------------------------------------------
  This CSS resource incorporates links to font software which is the valuable copyrighted
  property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
  redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
  Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/

//  Settings
@import "global/settings"; // Foundation settings file.

// Foundation mixins & browser resets
@import '../components/foundation-sites/scss/foundation';

// WP overrides
@import "global/wp-admin"; // Fix issues with wp-admin bar positioning
@import "global/wp-overrides"; // Override the default WordPress styling for some elements

// Third-party libraries
@import 'font-awesome';
@import 'motion-ui';

// Foundation global styles
@include foundation-global-styles;

// Individual foundation components
@include foundation-flex-grid;
@include foundation-flex-classes;

.no-flexbox {
  @include foundation-grid;
}

// @include foundation-grid;
// @include foundation-flex-grid;
// @include foundation-typography;
@include foundation-forms;
@include foundation-button;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
// @include foundation-top-bar;
// @include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;


// Motion UI
// @include motion-ui-transitions;
// @include motion-ui-animations;

// RosiePress Variables
@import "variables";
@import "client-style";
//@import "landing";


// Import Custom Styles for aw-custom-login
@import "../../custom-login/scss/aw-custom-login";

// Modules
// @import "modules/header";
// @import "modules/footer";
@import "modules/slick";
@import "modules/parallax";
@import "modules/editor-style";
@import "modules/contact";
@import "modules/gallery";
@import "modules/breadcrumbs";
@import "modules/menu_2";
@import "modules/share_this";
@import "modules/cf7";
@import "modules/aw_pagination";
// @import "modules/menu_3";

// Modules
// @import "modules/navigation";
@import "modules/footer";
// @import "modules/sidebar";
@import "modules/editor-style";

// Components
@import "components/buttons";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";

// Templates
@import "templates/front";
// @import "templates/kitchen-sink";
@import "templates/page-default";
@import "templates/page-sidebar-left";
@import "templates/page-full-width";
@import "templates/single-post";
