#single-post {
  @include grid-row();
  margin-top: 3rem;

  .main-content {
    @include breakpoint(small) {
      @include grid-column(12);
    }

    @include breakpoint(medium) {
      @include grid-column(8);
    }
  }
}
