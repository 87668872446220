.parallax-container {
	position: relative;
	.background {
		background: {
			repeat: no-repeat;
			position: 50% 50%;
			position: 50% 50%\9 !important;
			size: cover;
		}
	}
	.fullscreen {
		width: 100%;
		height: calc(100vw / 1920 * 750);
		overflow: hidden;
	}
	.fullscreen.overflow {
		height: auto;
		min-height: 100%;
	}
	.not-fullscreen {
		width: 100%;
		height: calc(100vw / 1920 * 450);
		overflow: hidden;
	}
	@media (max-width: 1023px) {
		.not-fullscreen {
			height: calc(100vw / 1920 * 900);
		}
		.fullscreen {
			height: calc(100vw / 1920 * 900);
		}
	}
	.parallax {
		position: relative;
	}
}