.aw-custom-login-form {
  input {
    margin-bottom: 0;
  }

  @include breakpoint(small down) {
    .btn-aw {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

}

.password-row {
  margin-bottom: 0;
}
.password-check-row {
  margin-top: 0;
}

#password-strength {
  height: 39px;
  width: 100%;
  border: 1px solid red;
  display: block;
  margin-bottom: 1em;
  font-weight: bold;
  text-align: center;
  padding: 5px 10px;
  // border-top: 0;
}

#password-strength.short {
	background-color: #f1adad;
	border-color: #e35b5b;
	opacity: 1;
}

#password-strength.bad {
	background-color: #fbc5a9;
	border-color: #f78b53;
	opacity: 1;
}

#password-strength.good {
	background-color: #ffe399;
	border-color: #ffc733;
	opacity: 1;
}

#password-strength.strong {
	background-color: #c1e1b9;
	border-color: #83c373;
	opacity: 1;
}
