#page-sidebar-left {
  @include grid-row();
  margin-top: 3rem;

  .main-content {
    @include breakpoint(small) {
      @include grid-column(12);
    }

    @include breakpoint(medium) {
      @include grid-column(8);
      @include grid-col-pos(4);
    }
  }

  .sidebar {
    @include breakpoint(small) {
      @include grid-column(12);
    }

    @include breakpoint(medium) {
      @include grid-column(4);
      @include grid-col-pos(-8);
      margin-top: 1rem;
    }
  }
}
