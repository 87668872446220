//
// Breadcrumbs
//

ol.breadcrumbs {
  margin: 0;
  padding: 0;

  li {
    display: inline;
    font-size: 14px;
    @include breakpoint(medium) {
      font-size: 20px;
    }
    // line-height:/ 41px;
    color: $white;
    margin-right: 10px;

    &.seperator {
      position: relative;
      padding-left: 6px;

      &:after {
        // content: '\0338';
        content: '';
        position: absolute;
        bottom: 3px;
        left: 0px;
        width: 1px;
        height: 12px;
        @include breakpoint(medium) {
          width: 1.5px;
          height: 15px;
          bottom: 5px;
        }
        transform: rotate(25deg);
        background: {
          color: $white;
        }
        display: inline-block;
        margin: 0 2px;
      }
    }

    a {
      color: $white;
      // @include Avenir-Medium;
      span {
        @include Avenir-Medium;
        // text-transform: uppercase
        color: $white;
      }
    }

    &:last-child {
      a {
        color: $white;
      }
    }
  }
}
